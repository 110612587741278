/* Bootstrap  */
//import "bootstrap";
import { Accordion } from 'bootstrap'

/* Custom SCSS */
import "./index.scss";

/* SwiperJS */
//import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
//import 'swiper/css';
//import 'swiper/css/navigation';
//import 'swiper/css/pagination';
//import 'swiper/css/scrollbar';
//Swiper.use([Scrollbar]);

/* Fancybox */
//import { Fancybox } from "@fancyapps/ui";
//import "@fancyapps/ui/dist/fancybox.css";

/* Gsap */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

var tlmenuOpen = gsap.timeline({ paused:true });
    tlmenuOpen
    .to('.navbar-collapse', { x:0, duration: .2, ease: "power4.in" })
    .from('#menu-main-menu > .menu-item', { autoAlpha: 0, x:100, stagger: .1, duration: .5 }, 'vocimenu')
    .from('.lang_switcher', { autoAlpha: 0, y: 50, duration: 3 }, 'vocimenu')
    .from('#menu_secondario', { autoAlpha: 0, y: 50, duration: 3 }, 'vocimenu')

var tlmenuClose = gsap.timeline({ paused:true });
    tlmenuClose
    .to('#menu-main-menu > .menu-item', { autoAlpha: 0, x:100, stagger: .1, duration: .5 }, 'chiusura')
    .to('.navbar-collapse', { x:'100vw', duration: .3, ease: "power4.in" }, 'chiusura')
    .to('.navbar-toggler.apri', { autoAlpha:1, duration: .1 });

var Isotope = require('isotope-layout');

jQuery(document).ready( function($) {

    // Aggiungi classe 'active' alla voce di menu corrente
    $('body').on('click', '.menu-item.menu-item-has-children .nav-link', function(e) {
        const dropdownMenu = $(this).next('.dropdown-menu');

        if (dropdownMenu.hasClass('submenu-show')) {
            // Chiudi il menu
            dropdownMenu.removeClass('submenu-show').addClass('submenu-hide').css({
                height: 0
            });
        } else {
            // Calcola altezza naturale + padding
            const padding = 16;

            const originalHeight = dropdownMenu.prop('scrollHeight');

            // Mostra il menu
            dropdownMenu.removeClass('submenu-hide').addClass('submenu-show').css({
                height: originalHeight + padding + 'px'
            });
        }
    });

    // Apre/chiude il menu principale
    $('.navbar-toggler').click(function() {
        if($(this).hasClass('chiudi')) {
            tlmenuClose.play(0);
        } else {
            tlmenuOpen.play(0);
        }
        $(this).toggleClass('collapsed');
        //$('#navbarToggler').toggleClass('aperto');
        $('body').toggleClass('aperto');
        $('body').toggleClass('overflow-hidden');
    });

    $('a.scroll-link, .scroll-link>a').click(function(e) {
        e.preventDefault();
        var link = $(this).attr('href');
        $('html, body').animate({ scrollTop: $('#'+link).offset().top-200}, 800);
    });

    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        var header_top = $('.header-top').outerHeight();

        if(scroll > header_top) {
            $('body').addClass('stickyHeader');
        } else {
            $('body').removeClass('stickyHeader');
        }

    });

    if($(window).width()<990) {
        $('.mobile-toggler .titolo_pb').click(function() {
            $(this).toggleClass('aperto');
            $(this).parents('.mobile-toggler').toggleClass('aperto');
        });
    }

    // ricerca ajax
    (function ($) {
        $.fn.delayKeyup = function(callback, ms){
            var timer = 0;
            $(this).keyup(function(){
                clearTimeout (timer);
                timer = setTimeout(callback, ms);
            });
            return $(this);
        };
    })(jQuery);

    for (let ii = 1; ii < 4; ii++) {
        $('.search_form #search-'+ii).delayKeyup(function(){

                var string = $('.search_form #search-'+ii).val();
                if(string.length>3) {
                    var security = $('.search_form #security').val();
                    $.ajax({
                        type: 'POST',
                        dataType: 'json',
                        url: ajax_auth_object.ajaxurl,
                        data: {
                            'action': 'ajaxsearch',
                            'string': string,
                            'security': security,
                            'lang': ajax_auth_object.languagecode
                        },
                        beforeSend: function() {
                            $('.search_results').html('<div class="lds-ripple"><div></div><div></div></div>');
                        },
                        success: function (data) {
                            var html = '<div class="row">';

                            $.each(data, function(index, item) {

                                html += '<article class="mb-2 mb-lg-3 col-12 col-lg-4 product type-product status-publish has-post-thumbnail">';
                                html += '<div class="wrapper"><div class="top">'+item.thumb+'</div>';
                                html += '<div class="bottom p-2"><h3 class="entry-title">'+item.post_title+'</h3><p>'+item.post_excerpt+'</p>';
                                html += '<a href="'+item.url+'" data-quantity="1" class="button product_type_variable add_to_cart_button">Scegli</a></div></div></article>';

                            });

                            html += '</div>';

                            $('.search_results').html(html);
                        }
                    });
                }
        }, 500);
    }

});

// Vanilla JS
document.addEventListener("DOMContentLoaded", function() {

    /* Animazione scroll con ancoraggio al footer  */
    const scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    const rootElement = document.documentElement;
    const footer = document.querySelector("#footer");

    // Verifica la larghezza dello schermo
    function isDesktop() {
        return window.innerWidth >= 992;
    }

    // Funzione per ottenere la distanza dal top di un elemento rispetto alla finestra
    function getOffsetTop(element) {
        var rect = element.getBoundingClientRect();
        return rect.top + window.pageYOffset - document.documentElement.clientTop;
    }

    // Funzione per controllare se il footer è visibile nella viewport
    function isFooterVisible() {
        var footerRect = footer.getBoundingClientRect();
        return footerRect.top <= window.innerHeight && footerRect.bottom >= 0;
    }

    // Funzione per gestire lo scroll e la posizione del bottone
    function handleScroll() {
        var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
        var scrollPercentage = rootElement.scrollTop / scrollTotal;
        var footerTop = getOffsetTop(footer);
        var btnHeight = scrollToTopBtn.offsetHeight;
        var scrollPos = window.pageYOffset + window.innerHeight;
        const isDesktopView = isDesktop();

        if (scrollPercentage > 0.1 && isDesktopView) {
            scrollToTopBtn.classList.add("showBtn");
        } else if (scrollPercentage > 0.1 && !isDesktopView) {
            scrollToTopBtn.classList.add("showBtn");
        } else {
            scrollToTopBtn.classList.remove("showBtn");
        }

        if (isDesktopView && isFooterVisible()) {
            scrollToTopBtn.classList.add("no-transition");

            // Ancorare il bottone sopra il footer, ma con metà dell'altezza del bottone che esce
            scrollToTopBtn.style.position = 'absolute';
            scrollToTopBtn.style.bottom = `${footer.clientHeight - btnHeight / 2}px`;
        } else if (isDesktopView) {
            scrollToTopBtn.style.position = 'fixed';
            scrollToTopBtn.style.bottom = '20px';

            // Rimuove la classe "no-transition" dopo aver passato il footer (oltre l'altezza del bottone)
            if (scrollPos < footerTop - btnHeight) {
                scrollToTopBtn.classList.remove("no-transition");
            }
        } else {
            // Comportamento normale su mobile (<992px)
            scrollToTopBtn.style.position = 'fixed';
            scrollToTopBtn.style.bottom = '20px';
        }
    }

    // Funzione per riportare la pagina in cima
    function scrollToTop() {
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    // Gestione dello scroll
    if(scrollToTopBtn) {
        scrollToTopBtn.addEventListener("click", scrollToTop);
    }
    document.addEventListener("scroll", handleScroll);

    window.addEventListener('resize', handleScroll);

    /* Isotope grid */
    const grid = document.querySelector('.CaseStudies__Grid');
    if(grid) {
        // Inizializza Isotope
        var iso = new Isotope('.CaseStudies__Grid', {
            itemSelector: '.CaseStudies__Grid .item',
            layoutMode: 'fitRows'
        });

        // Filtra in base al pulsante cliccato
        const filtersElem = document.querySelector('.filters');
        filtersElem.addEventListener('click', function(event) {
            // Controlla se l'elemento cliccato è un button
            if (!event.target.closest('button')) {
                return;
            }

            // Prendi il valore del filtro
            var filterValue = event.target.closest('button').getAttribute('data-filter');
            iso.arrange({ filter: filterValue });

            ScrollTrigger.refresh();

            // Rimuovi la classe 'active' dai pulsanti e aggiungila a quello cliccato
            filtersElem.querySelector('.active').classList.remove('active');
            event.target.closest('button').classList.add('active');
        });
    }

    /* Player e video */
    var players = document.querySelectorAll('.player');
    var videos = document.querySelectorAll('video');

    if (players && videos) {
        players.forEach(function (player, index) {
            var video = videos[index];

            // Funzione per gestire la visualizzazione del player
            function togglePlayer() {
                if (video.paused) {
                    player.style.display = 'block';
                } else {
                    player.style.display = 'none';
                }
            }

            // Aggiungi l'evento di click al player
            player.addEventListener('click', function () {
                video.play();
                togglePlayer();
            });

            // Aggiungi l'evento di click al video
            video.addEventListener('click', function () {
                if (video.paused) {
                    video.play();
                } else {
                    video.pause();
                }
                togglePlayer();
            });

            // Aggiungi l'evento quando il video termina
            video.addEventListener('ended', function () {
                player.style.display = 'block';
            });

            // Controlla lo stato del video all'inizio
            togglePlayer();
        });
    }

    // Aggiunge autoplay per video safari mobile
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var isMobile = /Mobi/.test(navigator.userAgent);
    var videoSection = document.querySelector('.video-section video');
    if (isSafari && isMobile && videoSection) {
        videoSection.setAttribute('autoplay', '');
    }

    /* Animazioni */

    // Attiva l'animazione con ScrollTrigger quando la sezione entra in viewport
    ScrollTrigger.create({
        trigger: ".animate-counter",
        start: "top 80%",
        onEnter: animateCounters,
        once: true
    });
    gsap.utils.toArray(".fade-in").forEach(function(elem) {
        if (!elem.classList.contains('fade-left') && !elem.classList.contains('fade-right')) {
            ScrollTrigger.create({
                trigger: elem,
                start: "top 80%",
                onEnter: function() {
                    animateOnce(elem);
                },
                onLeaveBack: function() {
                    if (!elem.classList.contains('animate-once')) {
                        animateOut(elem);
                    }
                },
                toggleActions: "play none none reverse"
            });
        }
    });

    // Evento click sul video
    const videoElement = document.querySelector('.video-section-expand video');

    if (videoElement) {
        videoElement.addEventListener('click', function() {
            if (window.innerWidth >= 992) {
                expandVideo();
            }
        });

        videoElement.addEventListener('play', function() {
            if (window.innerWidth >= 992) {
                expandVideo();
            }
        });
    }

    // Evento click sul pulsante di chiusura
    const closeButton = document.querySelector('.close-video');
    if (closeButton) {
        closeButton.addEventListener('click', function() {
            if (window.innerWidth >= 992) {
                collapseVideo();
            }
        });
    }

    // Aggiungi l'evento resize
    window.addEventListener('resize', handleResize);

    // Funzione per ripristinare il layout originale se la finestra viene ridimensionata
    function handleResize() {
        if (window.innerWidth < 992) {
            collapseVideo();
        }

        // stoppa il video
        const video = document.querySelector('.video-section video');
        if (video) {
            video.pause();
            // mostro player
            const player = document.querySelector('.player');
            player.style.display = 'block';
        }
    }

    // Funzione per animare i numeri con GSAP
    function animateCounters() {
        // Seleziona tutti gli elementi con la classe 'counter'
        const counters = document.querySelectorAll('.counter');

        // Per ogni contatore, anima da 0 al valore finale
        counters.forEach(counter => {
            // Trova la classe che inizia con "data-count-" e prendi il valore numerico
            const classes = Array.from(counter.classList);
            const countClass = classes.find(cls => cls.startsWith('data-count-'));
            const finalValue = parseInt(countClass.replace('data-count-', ''));

            // Ottieni il testo corrente del contatore (per preservare simboli come +, €, etc.)
            const initialText = counter.innerText;
            const nonNumericPart = initialText.replace(/[0-9]/g, '');

            gsap.fromTo(counter,
                { innerText: 0 },
                {
                    innerText: finalValue,
                    duration: 2,
                    ease: "power1.out",
                    snap: { innerText: 1 },
                    stagger: 0.2,
                    onUpdate: function() {
                        // Aggiorna il testo con i simboli (non-numeric part) e il valore arrotondato
                        counter.innerText = nonNumericPart + Math.ceil(counter.innerText);
                    }
                }
            );
        });
    }

    // Funzione per espandere il video e ridurre la sidebar
    function expandVideo() {
        const videoSection = document.querySelector("#content");
        const sidebar = document.querySelector(".header");
        const testoSidebar = document.querySelector('.testo_sidebar');
        const logo = document.querySelector('.logo');
        const body = document.querySelector('body');

        // Aggiungi la classe per espandere
        videoSection.classList.add("expandedVideo");
        sidebar.classList.add("collapsedVideo");

        // aggiungo overflow-hidden al body
        body.classList.add('overflow-hidden');

        // Nascondi testo e logo nella sidebar
        gsap.to(testoSidebar, {
            duration: 0.2,
            opacity: 0,
            ease: "power2.out",
            onComplete: function() {
                testoSidebar.style.display = 'none';
                logo.style.display = 'none';
            }
        });
    }

    // Funzione per ridurre il video e ripristinare la sidebar
    function collapseVideo() {
        const videoSection = document.querySelector("#content");
        const sidebar = document.querySelector(".header");
        const testoSidebar = document.querySelector('.testo_sidebar');
        const logo = document.querySelector('.logo');
        const body = document.querySelector('body');

        // Rimuovi la classe per tornare alle dimensioni originali
        videoSection.classList.remove("expandedVideo");
        sidebar.classList.remove("collapsedVideo");

        // rimuovo overflow-hidden al body
        body.classList.remove('overflow-hidden');


        // Mostra nuovamente il testo e il logo nella sidebar
        testoSidebar.style.display = 'block';
        logo.style.display = 'block';

        // blocca video
        const video = document.querySelector('.video-section video');
        if (video) {
            video.pause();
            // mostro player
            const player = document.querySelector('.player');
            player.style.display = 'block';
        }

        gsap.to(testoSidebar, {
            duration: 0.5,
            opacity: 1,
            ease: "power2.out"
        });
    }

    // Funzione per animare gli elementi
    function animateOnce(elem) {
        gsap.fromTo(elem,
            { opacity: 0 },
            {
                opacity: 1,
                duration: 1.25,
                ease: "expo",
                overwrite: "auto"
            }
        );
    }

    function animateOut(elem) {
        gsap.to(elem, {
            opacity: 0,
            duration: 1.25,
            ease: "expo",
            overwrite: "auto"
        });
    }

    /* Swiper init slides */

    const OneCardSlider = new Swiper('.OneCard__Slider .swiper', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        pagination: {
            el: '.OneCard__Slider .swiper-pagination',
            clickable: true,
        }
    });

    const logosSlider = new Swiper('.Logos__Slider .swiper', {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 15,
        loop: true,
        pagination: {
            el: '.Logos__Slider .swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                slidesPerGroup: 1,
            },
            992: {
                slidesPerView: 4,
                slidesPerGroup: 1,
            },
            1200: {
                slidesPerView: 6,
                slidesPerGroup: 6,
            },
        },
    });

    const caseStudiesSlider = new Swiper('.CaseStudies__Slider .swiper', {
        slidesPerView: 1,
        loop: true,
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
            nextEl: '.CaseStudies__Slider .swiper-button-next',
            prevEl: '.CaseStudies__Slider .swiper-button-prev',
        },
        breakpoints: {
            576: {
                centeredSlides: false,
                slidesPerView: 2,
            },
            991: {
                centeredSlides: false,
                slidesPerView: 2.25,
            },
        },
    });

    const sliders = document.querySelectorAll('.slider_default');

    sliders.forEach(slider => {
        const swiper = new Swiper(slider.querySelector('.swiper'), {
            slidesPerView: 1,
            loop: true,
            autoplay: {
                delay: 5000,
            },
            centeredSlides: true,
            spaceBetween: 40,
            navigation: {
                nextEl: slider.querySelector('.swiper-button-next'),
                prevEl: slider.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                768: {
                    centeredSlides: false,
                    slidesPerView: 2,
                    navigation: false,
                },
                991: {
                    centeredSlides: false,
                    slidesPerView: 2.25,
                },
            },
        });
    });

    const serviceSlider = new Swiper('.slider_service .swiper', {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 20,
        breakpoints: {
            991: {
                slidesPerView: 2,
            },
            1500: {
                slidesPerView: 3,
            }
        },
    });

    const swiperSlider = new Swiper('.slider_banner', {
        slidesPerView: 1,
        loop: true,
    });
});
